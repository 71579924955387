
::v-deep {
    // .v-slide-group__wrapper {
    //     overflow: visible;
    // }
    .v-slide-group__next,
    .v-slide-group__prev {
        display: none !important;
    }
    .v-tab {
        text-transform: initial;
    }
    .active {
        background: rgb(236, 234, 234);
    }
}
