
.showcase-menu {
    border: none;
    background-color: transparent;

    overflow: visible;
    contain: none;

    .v-btn.v-btn--icon {
        width: auto !important;
        height: auto !important;
    }
}
.showcase-menu .v-card {
    margin-top: 6px;
}
.showcase-menu .v-card--link:focus:before,
.showcase-menu .v-card .v-ripple__container {
    display: none !important;
}
@media (min-width: 768px) {
    .showcase-menu {
        transform: translateX(-135px);
    }
}
