
.dot {
    font-size: 6px;
    margin-top: 5px;
}
@media (min-width: 768px) {
    .dot {
        margin-top: 7px;
    }
}
