
.schedule-settings-list {
    > li {
        margin-bottom: 34px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.select-week-day {
    margin: 0 -8px;
    .v-btn {
        width: 36px !important;
        height: 36px !important;
        min-width: initial !important;
        border-radius: 50% !important;
        border: none !important;
        background-color: var(--v-grey-lighten2) !important;
        color: #fff;
        font-weight: bold;
        margin: 0 8px;
        padding: 0;
        &--active {
            background-color: var(--v-secondary-base) !important;
        }
    }
}
