
.sub-visual {
    height: 360px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: calc(-1 * var(--page-section-padding-y) - 42px);
    .tit {
        margin-bottom: calc(var(--page-section-padding-y) + 42px);
    }
    &__bg {
        background: center no-repeat;
        background-size: cover;
        width: calc(100% + 2px);
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }
    &__path {
        position: absolute;
        bottom: calc(var(--page-section-padding-y) + 42px);
        display: flex;
        justify-content: flex-end;
        color: #fff;
        ::v-deep() {
            .v-icon {
                color: #fff !important;
            }
            .v-breadcrumbs {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }
}
::v-deep {
    .theme--light.v-breadcrumbs .v-breadcrumbs__divider,
    .theme--light.v-breadcrumbs .v-breadcrumbs__item--disabled {
        color: #fff;
    }
}
@media (min-width: 1200px) {
    .sub-visual {
        height: 540px;
    }
}
