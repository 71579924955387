
.v-btn {
    border-radius: 0 !important;
    width: 50%;
    background: #fff !important;
    color: var(--v-grey-base);
    &:nth-child(3) {
        border-left-width: 1px !important;
    }
    &:nth-child(n + 3) {
        border-top-width: 0;
    }

    &--active {
        background: var(--v-primary-base) !important;
    }
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
    border-color: var(--v-primary-base) !important;
}
