
.v-data-table {
    border-top: 2px solid var(--v-primary-base);
    tr {
        border-bottom: 1px solid var(--border-color);
        > th {
            text-align: center !important;
            border-right: 1px solid var(--border-color);
        }
    }
}
