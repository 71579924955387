
.rider-story-slide {
    // position: relative;
    border-top: 2px solid var(--v-primary-base);
    border-bottom: 2px solid var(--v-primary-base);
    height: 615px;
    .swiper-slide {
        border-bottom: 1px solid var(--v-primary-base);
        &:first-child {
            border-top: 1px solid var(--v-primary-base);
        }
    }
}
@media (min-width: 1024px) {
    .rider-story-slide {
        height: 496px;
        .swiper-slide {
            border-bottom: 1px solid var(--v-primary-base);
            &:first-child {
                border-top: 1px solid var(--v-primary-base);
            }
        }
    }
}
