
.page-section {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        width: 100%;
        max-width: var(--container-lg);
        height: 1px;
        background: var(--border-color);
    }
    &--first {
        padding-top: 0;
        ::v-deep() {
            .container {
                padding-top: var(--page-section-padding-y);
                padding-left: 0;
                padding-right: 0;
                max-width: calc(100% - var(--container-gutter) * 2);
                background-color: #fff !important;
            }
        }
    }
    &--last {
        &::after {
            display: none;
        }
    }
}
@media (min-width: 1200px) {
    .page-section {
        &--first {
            ::v-deep() {
                .container {
                    &--lg {
                        max-width: var(--container-lg);
                    }
                }
            }
        }
    }
}
