
::v-deep() {
    .v-date-picker {
        &-header {
            justify-content: center;
            &__value {
                flex: initial;
                font-size: var(--tit-font-size-xxs);
            }
            .v-btn {
                background-color: transparent !important;
            }
        }
    }
    .v-date-picker-table {
        height: auto !important;
        td {
            padding: 20px 0;
        }
    }
}
