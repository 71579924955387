
.v-slide-group {
    ::v-deep() {
        .v-slide-group__wrapper {
            margin: 0 -10px;
            position: relative;
            &::before,
            &::after {
                content: "";
                position: absolute;
                top: 0;
                width: 30px;
                height: 100%;
            }
            // &::before {
            //     z-index: 1;
            //     left: 0;
            //     background-image: linear-gradient(to right, rgb(255, 255, 255) 50%, transparent 100%);
            // }
            // &::after {
            //     right: 0;
            //     background-image: linear-gradient(to left, rgb(255, 255, 255) 50%, transparent 100%);
            // }
        }
        .v-slide-group__prev,
        .v-slide-group__next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background: var(--v-primary-base);
            width: 32px;
            height: 32px;
            border-radius: 999px;
            min-width: initial !important;
            .v-icon {
                color: #fff !important;
            }
            &--disabled {
                background-color: var(--v-grey-lighten3);
            }
        }
        .v-slide-group__prev {
            position: absolute;
            top: 50%;
            left: -48px;
            width: 32px;
            height: 32px;
            min-width: initial !important;
            transform: translateY(-50%);
        }
        .v-slide-group__next {
            right: -48px;
        }
        .v-slide-group__prev + .v-slide-group__wrapper {
            &::after {
                display: none;
            }
        }
        .v-slide-group__prev--disabled + .v-slide-group__wrapper {
            &::after {
                display: block;
            }
            &::before {
                display: none;
            }
        }
    }
}

.product-item {
    margin: 0 10px;
    &__inner {
        width: 100%;
        min-width: 186px;
        max-width: calc(50vw - 20px);
    }
}

@media (min-width: 1200px) {
    .product-item {
        max-width: 252px;
    }
}
