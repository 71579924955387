
.notice-slide {
    padding-bottom: 40px;
}
.swiper-button-prev,
.swiper-button-next {
    height: 16px;
    width: calc(16px / 44 * 27);
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: var(--v-grey-base);
        z-index: -1;
    }
    &::after {
        font-size: 16px;
        color: #fff;
    }
}
.swiper-button-prev {
    left: 18px;
}
.swiper-button-next {
    right: 18px;
}
.swiper-pagination {
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;

    ::v-deep() {
        .swiper-pagination-bullet {
            width: 6px;
            height: 6px;
            margin: 0 2px;
            border: 0.5px solid var(--v-primary-base);
            &-active {
                background-color: var(--v-primary-base);
            }
        }
    }
}

@media (min-width: 1024px) {
    .notice-slide {
        padding: 0 64px;
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            width: 64px;
            height: 100%;
            background: #fff;
            z-index: 2;
        }
        &::before {
            left: 0;
        }
        &::after {
            right: 0;
        }
    }
    .swiper-button-prev,
    .swiper-button-next {
        height: 32px;
        width: calc(32px / 44 * 27);
        &::before {
            display: none;
        }
        &::after {
            font-size: 32px;
            color: var(--v-primary-base);
        }
    }
    .swiper-button-prev {
        left: 20px;
    }
    .swiper-button-next {
        right: 20px;
    }
    .swiper-pagination {
        left: 64px;
        transform: initial;
        bottom: 0;

        ::v-deep() {
            .swiper-pagination-bullet {
                width: 8px;
                height: 8px;
                margin: 0 4px;
                &-active {
                    background-color: var(--v-primary-base);
                }
            }
        }
    }
}
