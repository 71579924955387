
.hot-best-card {
    border-top-width: 0;
}
@media (min-width: 1024px) {
    .hot-best-card {
        border-top-width: 1px;
        border-left-width: 0;
    }
}
