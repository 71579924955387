
.v-btn-toggle {
    flex-wrap: wrap;
    .v-btn {
        width: 100%;
        border-radius: 0 !important;
        border-bottom-width: 0 !important;
        border-left-width: 1px !important;

        background-color: #fff !important;
        color: var(--v-primary-base);
        &:last-child {
            border-bottom-width: 1px !important;
        }
        &--active {
            background-color: var(--v-secondary-base) !important;
        }
    }

    .v-btn--disabled.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: var(--v-grey-lighten5) !important;
        color: var(--v-grey-base) !important;
    }
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
    border-color: var(--border-color) !important;
}
