
.mini-shop-visual {
    position: relative;
    height: 300px;
    &__bg {
        background: center no-repeat;
        background-size: cover;
        width: calc(100% + 2px);
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        .overlay {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.2);
        }
    }
}
.container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .v-btn {
        position: absolute;
        top: var(--container-gutter);
        left: var(--container-gutter);
    }
}
