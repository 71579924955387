
.request-card {
    &--pause {
        position: relative;
        &::after {
            content: "서비스\A일시정지";
            white-space: pre;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.6);
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: #fff;
            font-size: var(--tit-font-size);
            font-weight: 700;
        }
    }
}
