
.select-time {
    flex-wrap: wrap;
    border-bottom: 1px solid var(--border-color);
    .v-btn {
        width: 100%;
        border: 1px solid var(--border-color);
        border-radius: 0 !important;
        border-bottom-width: 0 !important;
        background-color: #fff !important;
        color: var(--v-primary-base);
        font-weight: 500 !important;
        &.reserved {
            background-color: var(--v-grey-lighten5) !important;
            color: var(--v-grey-base) !important;
        }
    }
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
    border-color: var(--border-color) !important;
}
