
.search-drawer-btn {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    &__bg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.search-drawer-closer {
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
    width: 88px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    &__bg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.search-drawer {
    z-index: 999;
    max-width: 100%;
    max-height: 90% !important;
    position: fixed;
    bottom: 0;
    overflow: visible;
}
