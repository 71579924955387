
.map-sheet-actions-search {
    position: absolute;
    right: 50%;
    top: 78px;
    transform: translateX(50%) translateY(-150%);
    z-index: 2;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .map-sheet-actions-search {
        top: 100%;
    }
}
@media (min-width: 1200px) {
}
