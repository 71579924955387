
.v-tab {
    border-bottom: 1px solid var(--v-primary-base);
}

@media (min-width: 1024px) {
    .v-tab {
        &::after {
            max-width: calc(60%);
        }
    }
}
