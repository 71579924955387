
::v-deep {
    .v-data-table__wrapper > table {
        & > thead > tr,
        & > tbody > tr {
            border-bottom: none !important;

            & > th,
            & > td {
                padding: 4px !important;
            }

            td {
                &:first-child {
                    text-wrap: nowrap;
                }
                &:nth-child(2) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: normal;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }
            }
        }

        & > tbody > tr {
            cursor: pointer;
        }
    }
}

.story-list-card {
    border-top-width: 0;
}
@media (min-width: 1024px) {
    .story-list-card {
        border-top-width: 1px;
        border-left-width: 0;
    }
}
