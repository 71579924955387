
.day-btn {
    &,
    > .v-btn {
        width: 44px !important;
        min-width: 44px !important;
        height: 44px !important;
        padding: 0;

        &.disabled {
            color: var(--v-grey-lighten3) !important;
        }
    }

    > .v-btn--disabled.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        color: var(--v-grey-lighten3) !important;
        background-color: transparent !important;
    }
}
.holiday-btn {
    position: absolute;
    bottom: 50%;
    left: 100%;
    transform: translateY(50%);

    width: max-content !important;
    min-width: max-content !important;
    z-index: 2;
}

@media (max-width: 560px) {
    .day-btn {
        &,
        > .v-btn {
            width: 32px !important;
            min-width: 32px !important;
            height: 32px !important;
        }
    }
}

@media (max-width: 400px) {
    .day-btn {
        &,
        > .v-btn {
            width: 28px !important;
            min-width: 28px !important;
            height: 28px !important;
        }
    }
}

@media (max-width: 320px) {
    .day-btn {
        &,
        > .v-btn {
            width: 24px !important;
            min-width: 24px !important;
            height: 24px !important;
        }
    }
}
