
.add-button {
    background-color: var(--v-grey-lighten5) !important;
    border: 1px dashed var(--v-grey-base);
}
.v-list.categories {
    border: 1px solid var(--border-color);
    .v-list-item {
        border-bottom: 1px solid;
        border-color: var(--border-color) !important;
        &:last-child {
            border-bottom: 0;
        }
    }
}
