
.attachment-img {
    &::before {
        content: "";

        position: absolute;
        top: 0;
        left: 0;

        border-width: 2px;
        border-style: solid;
        border-color: transparent;

        width: 100%;
        height: 100%;
        background-color: transparent;

        opacity: 1 !important;
        z-index: 1;
    }
    &.thumb::before {
        border-color: var(--v-secondary-base);
    }
    &.onTab::before {
        border-color: green;
    }
    &__close {
        z-index: 2;
    }
    &__image {
        z-index: 0;
    }
}
