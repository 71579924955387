
::v-deep {
    .v-calendar-monthly,
    .v-calendar-weekly__day,
    .v-calendar-weekly__head-weekday {
        border: none !important;
    }
    .v-outside {
        background-color: transparent !important;
    }
    .v-past,
    .v-present,
    .v-future {
        color: var(--v-grey-lighten2) !important;
    }
    .v-calendar-weekly__head-weekday {
        text-transform: capitalize;
    }
    .v-calendar-weekly__day {
        overflow: visible;
    }
}
