
.new-store-btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    width: 16px;
    height: 88px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &__bg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.new-store-drawer {
    width: auto !important;
    height: 100%;
    z-index: 999;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    overflow: visible;
    .new-store-btn--close {
        left: 2px;
        transform: translate(-100%, -50%);
    }
    // ::v-deep() {
    //     .v-navigation-drawer__border {
    //         display: none;
    //     }
    // }
}
